import React from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import ReactGA from 'react-ga4';

import PerfectScrollbar from 'react-perfect-scrollbar';
import TopicItem from '../Topics/TopicItem/TopicItem';
import Fader from '../Loaders/Fader';

import SearchIcon from '../../../assets/images/icons/search.svg';
import SearchIconWhite from '../../../assets/images/icons/search-white.svg';
import Logo from '../../../assets/images/logo.png';

const TrendsList = ({ isOpen, setIsOpen, menuRef, setTitle, activeTopicId, setActiveTopicId, userDataState }) => {
  const config = useSelector((state) => state.config.config);

  const { trends } = useOutletContext();

  const handleTopicSelection = (label, id) => {
    setTitle(label);
    setIsOpen(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setActiveTopicId(id);
    ReactGA.event({
      category: 'trend_topic',
      action: 'click',
      label: `User ${userDataState.firstname} ${userDataState.lastname} viewed ${label}`,
      value: id,
    });
  };

  return (
    <div className={`pf-left ${isOpen ? 'open' : ''}`} ref={menuRef}>
      <div className="logo">
        <img src={Logo} />
      </div>
      <div className="menu">
        {trends ? (
          <PerfectScrollbar
            options={{
              wheelPropagation: false,
              autoHide: false,
              wheelSpeed: 0.7,
            }}
          >
            <div className="menu-content">
              {trends.map((item) => (
                <TopicItem
                  key={item.id}
                  data={item}
                  topicHandler={handleTopicSelection}
                  activeTopic={activeTopicId}
                  topicIcon={SearchIcon}
                  topicIconWhite={SearchIconWhite}
                />
              ))}
            </div>
          </PerfectScrollbar>
        ) : (
          <div className="sentiment-charts">
            <Fader />
          </div>
        )}
      </div>
      <div className="menu-footer">
        <div className="disclaimer">&copy; {config.disclaimer}</div>
      </div>
    </div>
  );
};

export default TrendsList;
