import React, { useEffect, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import Fader from '../../Loaders/Fader';
import DataGridComponent from '../../Forms/DataGrid/DataGrid';

import useOutsideClick from '../../../../hooks/useOutsideClick';

import MapIcon from '../../../../assets/images/icons/map.svg';

const InterestByRegion = ({ activeTopic, loading }) => {
  const [interestByRegionData, setInterestByRegionData] = useState([]);
  const [faq, setFaq] = useState(false);

  const { trends } = useOutletContext();

  const faqRef = useRef();
  const faqIconRef = useRef();

  useOutsideClick(faqRef, faqIconRef, faq, () => setFaq(false));

  const toggleFaq = () => {
    setFaq((prevState) => !prevState);
  };

  useEffect(() => {
    if (trends && activeTopic) {
      setInterestByRegionData(activeTopic.interest_by_region);
    }
  }, [trends, activeTopic]);

  return (
    <div className="interest chart-component keywords-chart">
      <div ref={faqIconRef} className="info-icon" onClick={toggleFaq}>
        i
      </div>
      {faq && (
        <div className="info-box" ref={faqRef}>
          <div className="ii-copy">
            <div className="iic-sub">Interest by subregion</div>
            <div className="iic-exp">
              See in which location your term was most popular during the specified time frame. Values are calculated on
              a scale from 0 to 100, where 100 is the location with the most popularity as a fraction of total searches
              in that location, a value of 50 indicates a location which is half as popular. A value of 0 indicates a
              location where there was not enough data for this term.
            </div>
            <div className="iic-exp">
              <b>Note:</b> A higher value means a higher proportion of all queries, not a higher absolute query count.
              So a tiny country where 80% of the queries are for "bananas" will get twice the score of a giant country
              where only 40% of the queries are for "bananas".
            </div>
          </div>
        </div>
      )}
      <div className="chart-title align-left">
        <img src={MapIcon} /> Interest by subregion
      </div>
      <div className="sentiment-charts">
        {!loading ? (
          interestByRegionData.length ? (
            interestByRegionData
              .sort((a, b) => b.value - a.value)
              .map((item, index) => (
                <DataGridComponent key={index} dataNumber={index + 1} data={item} type={'trend'} truncateText={false} />
              ))
          ) : (
            <div className="no-data">No Data Available. Please Check Back Shortly.</div>
          )
        ) : (
          <Fader />
        )}
      </div>
    </div>
  );
};

export default InterestByRegion;
