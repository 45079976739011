import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DateDropdown from '../../DateDropdown/DateDropdown';
import Fader from '../../Loaders/Fader';

import { getSummary } from '../../../../http/dataAPI';

import './Summary.scss';

const Summary = ({ activeTopicId }) => {
  const config = useSelector((state) => state.config.config);

  const [loading, setLoading] = useState(false);
  const [summaryData, setSummaryData] = useState(null);
  const [activeSummary, setActiveSummary] = useState(null);

  const handleGetSummaryData = async (searchId) => {
    setLoading(true);
    setSummaryData(null);
    setActiveSummary(null);

    const res = await getSummary(config, searchId);
    if (res.status === 200) {
      if (res.data) {
        setSummaryData(res.data);
        setActiveSummary(res.data[0]);
      }
      setLoading(false);
    } else {
      console.log('ERROR: Grabbing Summary data');
    }
  };

  useEffect(() => {
    if (config.api) {
      handleGetSummaryData(activeTopicId || 0);
    }
  }, [config, activeTopicId]);

  return (
    <div className="summary chart-component">
      <div className="chart-title align-left">
        AI Topic Summary
        {!loading && summaryData && (
          <DateDropdown
            type="summary-date"
            data={summaryData}
            activeItem={activeSummary}
            setActiveItem={setActiveSummary}
          />
        )}
      </div>
      {!loading ? (
        summaryData ? (
          <ul className="summary__list">
            {activeSummary.summary.map((elem, i) => (
              <li key={i} className="summary__item">
                {elem}
              </li>
            ))}
          </ul>
        ) : (
          <div className="no-data">No Data Available. Please Check Back Shortly.</div>
        )
      ) : (
        <div className="sentiment-charts">
          <Fader />
        </div>
      )}
    </div>
  );
};

export default Summary;
