import React, { useState, useMemo, useEffect } from 'react';

import { truncate } from '../../../../util/HelperFunctions';

import LinkIcon from '../../../../assets/images/icons/link.svg';
import LinkOpenIcon from '../../../../assets/images/icons/link-open.svg';
import ArrowRightIcon from '../../../../assets/images/icons/arrow-right.svg';
import './DataGrid.scss';

const DataGridComponent = ({ data, type = 'link', truncateText = true, useNumbers = true, dataNumber = 0 }) => {
  const [total, setTotal] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const handleRowClick = () => {
    if (type === 'trend') {
      setIsOpen((prevValue) => !prevValue);
    }
  };

  const handleOpenLink = () => {
    if (type === 'link') {
      window.open(data.full_link, '_blank', 'noreferrer');
    }
  };

  useMemo(() => {
    const number = Intl.NumberFormat('en-US').format(data.total);
    setTotal(number);
  }, [total]);

  useEffect(() => {
    if (type === 'trend') {
      setIsOpen(false);
    }
  }, [data]);

  return (
    <div className="data-row">
      <div className={`data-link ${type === 'trend' && isOpen ? 'open' : ''}`} onClick={handleRowClick}>
        <div className="data-link-left" onClick={handleOpenLink}>
          {type === 'link' ? (
            <>
              {useNumbers ? <span className="list-number">{dataNumber}</span> : <img src={LinkIcon} />}
              <span>{truncateText ? truncate(data.link, 25) : data.link}</span>
              <input type="text" value={data.link} onChange={() => {}} />
            </>
          ) : (
            <>
              {type === 'trend' && (
                <div class={`toggle ${isOpen ? 'active' : ''}`}>
                  <div class="line horizontal"></div>
                  <div class="line vertical"></div>
                </div>
              )}
              {useNumbers ? (
                <span className="list-number">{dataNumber}</span>
              ) : (
                <img src={ArrowRightIcon} className="sm" />
              )}
              <span>{truncateText ? truncate(data.text, 16) : type === 'trend' ? data.region : data.text}</span>
              <input type="text" value={type === 'trend' ? data.region : data.text} onChange={() => {}} />
            </>
          )}
        </div>
        <div className="data-link-icon">
          <span>({type === 'trend' ? data.value : total})</span>
          {type === 'trend' && (
            <div className="progressbar-container">
              <div className="progressbar" style={{ width: `${data.value}%` }} title={`${data.value}%`}></div>
            </div>
          )}
          {type === 'link' ? <img src={LinkOpenIcon} onClick={handleOpenLink} /> : ''}
        </div>
      </div>
      {type === 'trend' && isOpen && (
        <div className="data-expanded">
          {data.interest_by_city.length > 0 ? (
            data.interest_by_city
              .sort((a, b) => b.value - a.value)
              .map((elem, i) => (
                <div className="data-link" key={i}>
                  <div className="data-link-left">
                    <>
                      <span className="list-number">{i + 1}</span>
                      <span>{elem.city}</span>
                      <input type="text" value={elem.city} onChange={() => {}} />
                    </>
                  </div>
                  <div className="data-link-icon">
                    <span>({elem.value})</span>
                    <div className="progressbar-container">
                      <div className="progressbar" style={{ width: `${elem.value}%` }}></div>
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <div className="data-link no-data">No Data Available.</div>
          )}
        </div>
      )}
    </div>
  );
};

export default DataGridComponent;
