import React from 'react';

import { LineChart } from '@mui/x-charts';

const LineChartComponent = ({
  data = [],
  width = 1000,
  axisData = [],
  xAxisData = [],
  useXAxis = false,
  useArea = false,
  showMark = false,
  showTime = false,
  keyToLabel = {
    positive: 'Positive',
    negative: 'Negative',
  },
  colorMap = {
    positive: '#05B08A',
    negative: '#C4001F',
  },
}) => {
  const handleRenderDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = showTime
      ? {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        }
      : { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const xAxisFormatData = [
    {
      dataKey: 'date',
      valueFormatter: (v) => handleRenderDate(v).toString(),
    },
  ];

  if (useXAxis) {
    return (
      <LineChart
        xAxis={xAxisFormatData}
        dataset={xAxisData}
        series={Object.keys(keyToLabel).map((key) => ({
          dataKey: key,
          label: keyToLabel[key],
          color: colorMap[key],
          showMark,
          area: useArea,
        }))}
        width={width}
        height={450}
      />
    );
  }

  return <LineChart xAxis={axisData} series={data} width={width} height={450} />;
};

export default LineChartComponent;
